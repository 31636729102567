import axios from "axios";

const API = axios.create({
  baseURL: "https://tollygram.azurewebsites.net/",
  // baseURL: "http://localhost:5000",
});

export const getTimelinePosts = (id) => API.get(`/posts/${id}/timeline`);
export const likePost = (id, userId) =>
  API.put(`posts/${id}/like`, { userId: userId });
export const getPost = async (id) => {
  try {
    const data = await API.get(`/posts/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};
export const addComment = (id, newComment) =>
  API.post(`/posts/${id}/comment`, { comment: newComment });
