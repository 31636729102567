import { Modal, RadioGroup, Radio } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ProfileModel.css";
import toast from "react-hot-toast";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../firebase/Firebase";
import { updateUser } from "../../actions/userAction";
import { useRef } from "react";
import getCroppedImg from "../imageCropper/cropImageFunctions";
import ImageCropper from "../imageCropper/ImageCropper";

function ProfileModel({ modelOpened, setModelOpened, data }) {
	const { password, ...other } = data;
	const [formData, setFormData] = useState(other);
	const [profilePicture, setprofilePicture] = useState(null);
	const dispatch = useDispatch();
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const { user } = useSelector((state) => state.authReducer.authData);
	const [openCropper, setOpenCropper] = useState(false);
	const [relationshipStatus, setRelationshipStatus] = useState(
		user.relationship
	);
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setprofilePicture(img);
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		let UserData = formData;
		dispatch(updateUser(user._id, UserData));
		toast.success("Updated");
	};
	const cropImage = useCallback(async () => {
		try {
			const dataurl = await getCroppedImg(
				URL.createObjectURL(profilePicture),
				croppedAreaPixels
			);
			const dataURLtoFile = (dataurl, filename) => {
				const arr = dataurl.split(",");
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) u8arr[n] = bstr.charCodeAt(n);

				return new File([u8arr], filename, { type: mime });
			};
			const temp = dataURLtoFile(dataurl, profilePicture.name);
			setCroppedImage(temp);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels]);
	const profilePicRef = useRef();
	const changeProfileDp = () => {
		cropImage();
	};
	useEffect(() => {
		upload();
	}, [croppedImage]);
	const upload = () => {
		if (!croppedImage) {
			return;
		}
		let UserData = formData;
		const filename = "displayPicture";
		const storageRef = ref(storage, `${user._id}/profilePicture/${filename}`);
		const uploadTask = uploadBytesResumable(storageRef, croppedImage);
		const promise = new Promise((resolve, reject) => {
			uploadTask.on(
				"state_changed",
				(snapshot) => {},
				(error) => {
					console.log(error);
					setCroppedImage(null);
					reject(error);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						UserData.profilePicture = url;
						dispatch(updateUser(user._id, UserData));
						setCroppedImage(null);
						resolve();
					});
				}
			);
		});
		toast.promise(promise, {
			loading: "Updating...",
			success: <b>Updated</b>,
			error: <b>Update Failed!</b>,
		});
	};
	return (
		<Modal
			centered
			opened={modelOpened}
			onClose={() => setModelOpened(false)}
			withCloseButton={false}
			size={380}
			overflow="inside"
		>
			<div className="infoForm">
				<div className="infoFormProfilePic">
					<img src={user.profilePicture} alt="" />
					<div>
						<span>{user.username}</span>
						<span
							onClick={() => {
								profilePicRef.current.click();
								setOpenCropper(true);
							}}
						>
							Change profile photo
						</span>
					</div>
				</div>
				{profilePicture && openCropper && (
					<div className="ProfileCrop">
						<div className="profileCropper">
							<ImageCropper
								image={URL.createObjectURL(profilePicture)}
								aspectRatio={1 / 1}
								setCroppedImage={setCroppedImage}
								setCroppedAreaPixels={setCroppedAreaPixels}
							/>
						</div>
						<div className="profileCropperOption">
							<span
								onClick={() => {
									setprofilePicture(null);
								}}
							>
								Cancel
							</span>
							<span
								onClick={() => {
									changeProfileDp();
									setOpenCropper(false);
								}}
							>
								Save
							</span>
						</div>
					</div>
				)}
				<div className="inputFormInputs">
					<div>
						<span>First Name</span>
						<input
							value={formData.firstname}
							onChange={handleChange}
							type="text"
							placeholder="First Name"
							name="firstname"
							className="infoInput"
						/>
					</div>
					<div>
						<span>Last Name</span>
						<input
							value={formData.lastname}
							onChange={handleChange}
							type="text"
							placeholder="Last Name"
							name="lastname"
							className="infoInput"
						/>
					</div>

					<div>
						<span>Work</span>
						<input
							value={formData.work}
							onChange={handleChange}
							type="text"
							placeholder="Works at"
							name="work"
							className="infoInput"
						/>
					</div>

					<div>
						<span>City</span>
						<input
							value={formData.city}
							onChange={handleChange}
							type="text"
							placeholder="Lives in"
							name="city"
							className="infoInput"
						/>
					</div>
					<div>
						<span>Country</span>
						<input
							value={formData.country}
							onChange={handleChange}
							type="text"
							placeholder="Country"
							name="country"
							className="infoInput"
						/>
					</div>

					<div className="relationshipRadio">
						<span>Relationship</span>
						<RadioGroup
							required
							spacing="xs"
							value={relationshipStatus}
							onChange={(e) => {
								setFormData({ ...formData, relationship: e });
								setRelationshipStatus(e);
							}}
						>
							<Radio value="Single" label="Single" />
							<Radio value="Commited" label="Commited" />
							<Radio value="Searching" label="Searching" />
							<Radio value="Dating" label="Dating" />
						</RadioGroup>
					</div>

					<div>
						<span>Bio</span>
						<textarea
							value={formData.desc}
							onChange={handleChange}
							type="text"
							placeholder="Description"
							name="desc"
							className="infoInput"
						/>
					</div>

					<div style={{ display: "none" }}>
						<input
							type="file"
							name="profilePicture"
							onChange={onImageChange}
							ref={profilePicRef}
						/>
					</div>
				</div>
				<div className="editProfileButtons">
					<button
						className="editProfileButton editProfileCancelButton"
						onClick={() => setModelOpened(false)}
					>
						Cancel
					</button>
					<button className="button editProfileButton" onClick={handleSubmit}>
						Update
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default ProfileModel;
