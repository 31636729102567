import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { TbPhoto } from "react-icons/tb";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "./PostShare.css";
import toast from "react-hot-toast";
import PostModel from "../postModel/PostModel";
import StoryModal from "../storyModal/StoryModal";

const PostShare = () => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const [postBio, setPostBio] = useState("");
	const [postModelOpened, setPostModelOpened] = useState(false);
	const [storyModelOpened, setStoryModelOpened] = useState(false);
	const [loading, setLoading] = useState(false);

	const placeholderText = `What's on your mind, ${user.firstname}?`;
	const desc = useRef();

	return (
		<>
			<PostModel
				postModelOpened={postModelOpened}
				setPostModelOpened={setPostModelOpened}
				postBio={{ postBio, setPostBio }}
			/>
			<StoryModal
				storyModelOpened={storyModelOpened}
				setStoryModelOpened={setStoryModelOpened}
			/>
			<div className="PostShare">
				<div className="postShareUpper">
					<div className="postShareSearchArea">
						<div className={user.story ? "haveStory" : ""}>
							<img
								src={user.profilePicture}
								alt=""
								className="postShareSearchAreaImage"
							/>
						</div>
						<input
							type="text"
							placeholder={placeholderText}
							className="postShareInput"
							ref={desc}
							required
							value={postBio}
							onChange={(e) => setPostBio(e.target.value)}
						/>
					</div>
					<hr />
					<div className="postOptions">
						<div className="option" onClick={() => setStoryModelOpened(true)}>
							<MdOutlineAddToPhotos
								style={{ color: "var(--story-icon-color)" }}
							/>
							Story
						</div>
						<div className="option" onClick={() => setPostModelOpened(true)}>
							<TbPhoto style={{ color: "var(--add-photo-icon-color)" }} />
							Photo
						</div>
						<div className="option" onClick={() => setPostModelOpened(true)}>
							<HiOutlineLocationMarker
								style={{ color: "var(--location-icon-color)" }}
							/>
							Location
						</div>
						<button
							className={`postShareButton ${
								loading ? "postShareButtonActive" : ""
							}`}
							onClick={() => {
								setLoading(true);
								toast.error("Please select a post to upload");
								setTimeout(() => {
									setLoading(false);
								}, 750);
							}}
						>
							<div className="svg-wrapper-1">
								<div className="svg-wrapper">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width="24"
										height="24"
									>
										<path fill="none" d="M0 0h24v24H0z"></path>
										<path
											fill="currentColor"
											d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
										></path>
									</svg>
								</div>
							</div>
							<span>Share</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default PostShare;
