import { getAllUser } from "../../api/userRequest";
import React, { useEffect, useState } from "react";
import "./ExploreSlide.css";
import ExploreCardSlideUser from "../exploreCardSlideUser/ExploreCardSlideUser";

const ExploreSlide = () => {
	const [persons, setPersons] = useState([]);
	useEffect(() => {
		const fetchPersons = async () => {
			const { data } = await getAllUser();
			setPersons(data);
		};
		fetchPersons();
	}, []);

	return (
		<div className="ExploreSlide">
			{persons.map((person, id) => {
				return <ExploreCardSlideUser person={person} key={id} />;
			})}
		</div>
	);
};

export default ExploreSlide;
