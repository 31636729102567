import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyB_Q8ZbMxegn3bV1GIC5muG9abpUp2WEj8",
	authDomain: "social-media-af1e7.firebaseapp.com",
	projectId: "social-media-af1e7",
	storageBucket: "social-media-af1e7.appspot.com",
	messagingSenderId: "602005297460",
	appId: "1:602005297460:web:06aef933ec093d9e702d61",
	measurementId: "G-7469S4MZDZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app, "gs://social-media-af1e7.appspot.com");
export default storage;
