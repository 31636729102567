import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FiEdit, FiLogOut } from "react-icons/fi";
import { RiSettings3Line } from "react-icons/ri";
import { GrLocation } from "react-icons/gr";
import { GiLovers } from "react-icons/gi";
import { MdWorkOutline, MdOutlineDangerous } from "react-icons/md";
import ProfileModel from "../../components/profileModel/ProfileModel";
import { Menu, Divider } from "@mantine/core";
import "./Profile.css";
import { useDisclosure } from "@mantine/hooks";
import { useDispatch } from "react-redux";
import { logOut } from "../../actions/authActions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as UserApi from "../../api/userRequest";
import toast from "react-hot-toast";
import { followUser, unfollowUser } from "../../actions/userAction";
import Loader from "../../components/loader/Loader";

const Profile = () => {
	let { userId } = useParams();

	const { user } = useSelector((state) => state.authReducer.authData);
	const [admin, setAdmin] = useState(false);
	const [clickedUser, setclickedUser] = useState(false);

	const [modelOpened, setModelOpened] = useState(false);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		dispatch({ type: "profile" });
	}, []);

	useEffect(() => {
		setLoading(false);
		if (clickedUser && clickedUser.data.user._id === user._id) setAdmin(true);
		if (clickedUser && user.following.includes(clickedUser.data.user._id)) {
			setFollowing(true);
		}
	}, [clickedUser]);

	useEffect(() => {
		const getUser = async () => {
			try {
				const user = await UserApi.getUser(userId);
				setclickedUser(user);
			} catch (error) {
				toast.error("Some error occured, try again");
				setLoading(false);
			}
		};
		getUser();
	}, [
		(clickedUser && user.following.includes(clickedUser.data.user._id)) || user,
	]);
	const dispatch = useDispatch();

	const [followLoading, setFollowLoading] = useState(false);
	const [following, setFollowing] = useState(false);

	useEffect(() => {
		if (clickedUser && user.following.includes(clickedUser.data.user._id)) {
			setFollowing(true);
			setFollowLoading(false);
		} else {
			setFollowing(false);
			setFollowLoading(false);
		}
	}, [clickedUser && user.following.includes(clickedUser.data.user._id)]);

	const handleFollow = async () => {
		setFollowLoading(true);
		if (following) {
			dispatch(unfollowUser(clickedUser.data.user._id, user));
		} else {
			dispatch(followUser(clickedUser.data.user._id, user));
		}
	};

	return (
		<>
			{!clickedUser && (
				<div className="profileLoader">
					<Loader />
				</div>
			)}
			{clickedUser && (
				<>
					{admin && (
						<ProfileModel
							modelOpened={modelOpened}
							setModelOpened={setModelOpened}
							data={clickedUser.data.user}
						/>
					)}
					<div className="Profile">
						<div className="profileUpper">
							<img src={clickedUser.data.user.profilePicture} alt="" />
							<div className="profileDetails">
								<span>
									<span>
										{clickedUser.data.user.username.length > 14
											? clickedUser.data.user.username.slice(0, 11) + "... "
											: clickedUser.data.user.username}
									</span>

									{admin && (
										<div onClick={() => setModelOpened(true)}>
											Edit profile <FiEdit />
										</div>
									)}
									{!admin && (
										<button className="button" onClick={handleFollow}>
											{followLoading
												? "loading"
												: following
												? "Unfollow"
												: "Follow"}
										</button>
									)}
								</span>
								<div>
									<span>
										0 <span>posts</span>
									</span>
									<span>
										{clickedUser.data.user.followers.length}{" "}
										<span>followers</span>
									</span>
									<span>
										{clickedUser.data.user.following.length}{" "}
										<span>following</span>
									</span>
								</div>
								<div className="profileDescription">
									<span>{`${clickedUser.data.user.firstname} ${clickedUser.data.user.lastname}`}</span>
									<span>{clickedUser.data.user.desc}</span>
									<div className="profileUserStatus">
										<span>
											<GrLocation /> {clickedUser.data.user.city}
										</span>
										<span>
											<GiLovers /> {clickedUser.data.user.relationship}
										</span>
										<span>
											<MdWorkOutline /> {clickedUser.data.user.work}
										</span>
									</div>
								</div>

								{admin && (
									<div className="profileSettingsSvg">
										<SettingsMenu />
									</div>
								)}
							</div>
						</div>
						<hr className="profileUpperDivider" />
						<div className="profileDescriptionMobile">
							<span>{`${clickedUser.data.user.firstname} ${clickedUser.data.user.lastname}`}</span>
							<span>{clickedUser.data.user.desc}</span>
							<div className="profileUserStatus">
								<span>
									<GrLocation /> {clickedUser.data.user.city}
								</span>
								<span>
									<GiLovers /> {clickedUser.data.user.relationship}
								</span>
								<span>
									<MdWorkOutline /> {clickedUser.data.user.work}
								</span>
							</div>
						</div>

						<div className="profileFollowersDataMobile">
							<hr />
							<div>
								<div>
									<span>0</span> <span>posts</span>
								</div>
								<div>
									<span>{clickedUser.data.user.followers.length}</span>{" "}
									<span>followers</span>
								</div>
								<div>
									<span>{clickedUser.data.user.following.length}</span>{" "}
									<span>following</span>
								</div>
							</div>
							<hr />
						</div>
						<div className="profileGallery">
							{loading
								? "Fetching Posts..."
								: clickedUser.data.userPosts.map((post, id) => {
										return (
											<div
												style={{ backgroundImage: `url(${post.image})` }}
											></div>
										);
								  })}
						</div>
					</div>
				</>
			)}
		</>
	);
};

function SettingsMenu() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [opened, handlers] = useDisclosure(false);
	const Logout = () => {
		dispatch(logOut());
		navigate("../auth");
	};
	return (
		<Menu
			opened={opened}
			onOpen={handlers.open}
			onClose={handlers.close}
			withArrow
			control={
				<button>
					<RiSettings3Line />
				</button>
			}
			size="sm"
			shadow="lg"
		>
			<Menu.Label>General</Menu.Label>
			<Menu.Item icon={<FiLogOut />} onClick={() => Logout()}>
				Logout
			</Menu.Item>
			<Menu.Item icon={<RiSettings3Line />}>Settings</Menu.Item>
			{/* <Menu.Item
				icon={<FiEdit />}
				rightSection={
					<Text size="xs" color="dimmed">
						⌘K
					</Text>
				}
			>
				Search
			</Menu.Item> */}

			<Divider />

			<Menu.Label>Danger zone</Menu.Label>
			<Menu.Item color="red" icon={<MdOutlineDangerous />}>
				sudo rm -rf /
			</Menu.Item>
		</Menu>
	);
}

export default Profile;
