import React, { useRef, useState, useCallback } from "react";
import "./PostModel.css";
import { Modal } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { FaGlobeAmericas } from "react-icons/fa";
import {
	MdOutlineLocationOn,
	MdOutlineAddPhotoAlternate,
} from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import ImageCropper from "../imageCropper/ImageCropper";
import toast from "react-hot-toast";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uploadPost } from "../../actions/uploadAction";
import storage from "../firebase/Firebase";
import getCroppedImg from "../imageCropper/cropImageFunctions";
import { useEffect } from "react";

const PostModel = (props) => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const dispatch = useDispatch();
	const postImageRef = useRef();
	const [aspectRatio, setAspectRatio] = useState(1 / 1);
	const [postImage, setPostImage] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [location, setLocation] = useState("");
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setPostImage(img);
		}
	};

	useEffect(() => {
		upload();
	}, [croppedImage]);

	const reset = () => {
		setPostImage(null);
		props.postBio.setPostBio("");
		setLocation(null);
		setCroppedImage(null);
		URL.revokeObjectURL(croppedImage);
	};

	const upload = () => {
		if (!croppedImage) {
			return;
		}
		const newPost = {
			userId: user._id,
			desc: props.postBio.postBio,
			location: location,
			userName: user.username,
			fullName: user.firstname + " " + user.lastname,
			userProfile: user.profilePicture,
		};
		const filename = Date.now() + postImage.name;
		const storageRef = ref(storage, `${user._id}/posts/${filename}`);
		const uploadTask = uploadBytesResumable(storageRef, croppedImage);
		const promise = new Promise((resolve, reject) => {
			uploadTask.on(
				"state_changed",
				(snapshot) => {},
				(error) => {
					console.log(error);
					setLoading(false);
					reject(error);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						newPost.image = url;
						dispatch(uploadPost(newPost));
						setLoading(false);
						reset();
						resolve();
					});
				}
			);
		});
		toast.promise(promise, {
			loading: "Uploading...",
			success: <b>Uploaded</b>,
			error: <b>Upload Failed!</b>,
		});
	};

	const handleSubmit = async () => {
		setLoading(true);
		if (!postImage) {
			toast.error("Please Select a photo to post");
			setTimeout(() => {
				setLoading(false);
			}, 600);
			return;
		}
		await cropImage();
	};

	const cropImage = useCallback(async () => {
		try {
			const dataurl = await getCroppedImg(
				URL.createObjectURL(postImage),
				croppedAreaPixels
			);
			const dataURLtoFile = (dataurl, filename) => {
				const arr = dataurl.split(",");
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) u8arr[n] = bstr.charCodeAt(n);

				return new File([u8arr], filename, { type: mime });
			};
			const temp = dataURLtoFile(dataurl, postImage.name);
			setCroppedImage(temp);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels]);

	return (
		<Modal
			centered
			opened={props.postModelOpened}
			onClose={() => props.setPostModelOpened(false)}
			withCloseButton={false}
			size={380}
			padding={0}
			transition="fade"
			transitionDuration={300}
		>
			<div className="postModel">
				<span> Create Post</span>
				<hr />
				<div className="postModelContent">
					<div className="postModelProfilData">
						<img src={user.profilePicture} alt="" />
						<div>
							<span>{`${user.firstname} ${user.lastname}`}</span>
							<div>
								<FaGlobeAmericas />
								<span>Public</span>
							</div>
						</div>
					</div>
					<textarea
						type="text"
						placeholder={`What's on your mind ${user.firstname} ?`}
						value={props.postBio.postBio}
						onChange={(e) => props.postBio.setPostBio(e.target.value)}
					/>
					<div
						className={`postmodelImageInput ${
							!postImage && "postmodelImageInputNoPhoto"
						}`}
					>
						{postImage && (
							<ImageCropper
								image={URL.createObjectURL(postImage)}
								aspectRatio={aspectRatio}
								setCroppedImage={setCroppedImage}
								setCroppedAreaPixels={setCroppedAreaPixels}
							/>
						)}
						{!postImage && (
							<div
								className="postmodelImageInputSelectPhoto"
								onClick={() => postImageRef.current.click()}
							>
								<MdOutlineAddPhotoAlternate />
								<span>select photo</span>
							</div>
						)}
					</div>
					{postImage && (
						<>
							<div className="aspectRatioSetter">
								<span onClick={() => setAspectRatio(1 / 1)}>1:1</span>
								<span onClick={() => setAspectRatio(4 / 5)}>4:5</span>
								<span onClick={() => setAspectRatio(1.91 / 1)}>1.9:1</span>
							</div>
							<div
								className="postCancelOption"
								onClick={() => {
									setPostImage(null);
								}}
							>
								<RiDeleteBinLine />
								Delete
							</div>
						</>
					)}

					<div className="profilemodelLocationInput">
						<MdOutlineLocationOn />
						<input
							type="text"
							placeholder="Add location"
							value={location}
							onChange={(e) => setLocation(e.target.value)}
						/>
					</div>
				</div>
				<div className="postModelButtonWrapper">
					<button
						className={`postModelButton ${loading && "postModelButtonActive"}`}
						onClick={handleSubmit}
					>
						<div className="svg-wrapper-1">
							<div className="svg-wrapper">
								<svg
									stroke="currentColor"
									fill="currentColor"
									strokeWidth="0"
									viewBox="0 0 24 24"
									height="1.8em"
									width="1.8em"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"></path>
								</svg>
							</div>
						</div>
						<span className="postModelButtonText">Post</span>
						<span className="postModelButtonTextLoading">Uploading...</span>
					</button>
				</div>
			</div>
			<div style={{ display: "none" }}>
				<input
					type="file"
					name="myImage"
					ref={postImageRef}
					onChange={onImageChange}
				/>
			</div>
		</Modal>
	);
};

export default PostModel;
