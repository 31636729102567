import React from "react";
import { useSelector } from "react-redux";
import Posts from "../posts/Posts";
import PostShare from "../postShare/PostShare";
import Stories from "../stories/Stories";
import "./PostSide.css";

const PostSide = () => {
	const { user } = useSelector((state) => state.authReducer.authData);
	return (
		<div id="PostSide">
			<Stories id={user._id} />
			<PostShare />
			<Posts />
		</div>
	);
};

export default PostSide;
