import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPost } from "../../api/postRrequest";
import "./PostPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addComment } from "../../api/postRrequest";
import Comment from "../../components/comment/Comment";
import { getUserBasic } from "../../api/userRequest";
import Loader from "../../components/loader/Loader";
// import toast from "react-hot-toast";

const PostPage = () => {
	const dispatch = useDispatch();
	const { postId } = useParams();
	const { user } = useSelector((state) => state.authReducer.authData);
	const [post, setPost] = useState(null);
	const [update, setUpdate] = useState(1);
	const [comment, setComment] = useState("");
	const navigate = useNavigate();
	const tx = document.getElementsByClassName("commentInput");
	const rx = document.getElementsByClassName("commentPostButton");
	const [author, setAuthor] = useState("");
	useEffect(() => {
		const fetchPost = async () => {
			const post = await getPost(postId);
			const author = await getUserBasic(post.data.userId);
			setPost(post.data);
			setAuthor(author.data);
		};
		fetchPost();
	}, [update]);
	useEffect(() => {
		for (let i = 0; i < tx.length; i++) {
			tx[i].setAttribute("style", "height: 18px;");
			rx[0].setAttribute("style", "height: 18px;");
			tx[i].addEventListener("input", OnInput, false);
		}
		dispatch({ type: "comments" });
	}, []);

	function OnInput() {
		rx[0].style.height = "18px";
		this.style.height = "18px";
		this.style.height = this.scrollHeight + "px";
		rx[0].style.height = this.scrollHeight + "px";
	}

	const handleClick = async () => {
		const newComment = {
			userId: user._id,
			data: comment,
			likes: [],
			replies: [],
		};
		try {
			await addComment(postId, newComment);
			setUpdate((prev) => prev + 1);
			setComment("");
		} catch (error) {
			console.log("error");
		}
	};
	return (
		<>
			<div className="PostPage">
				<div className="addCommentSection">
					<img src={user.profilePicture} alt="" />
					<div>
						<textarea
							type="text"
							className="commentInput"
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							placeholder="Add a comment..."
						/>
						<div className="commentPostButton">
							<span
								className={comment ? "" : "faintCommentPostButton"}
								onClick={handleClick}
							>
								Post
							</span>
						</div>
					</div>
				</div>
				{(!post || !author) && (
					<div className="commentLoader">
						<Loader />
					</div>
				)}
				{post && author && (
					<div className="commentDisplaySection">
						<div className="postDataCommnetSection">
							<img src={author.profilePicture} alt="" />
							<div>
								<span
									onClick={() => {
										navigate(`../${author.userName}`);
									}}
								>
									{author.username}
								</span>
								<span>{post.desc}</span>
							</div>
						</div>

						<div className="commentPageComments">
							{post.comments.map((comment, id) => {
								return <Comment key={id} comment={comment} />;
							})}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default PostPage;
