import { BiDotsHorizontalRounded } from "react-icons/bi";
import { RiSendPlaneLine } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import { AiOutlineHeart, AiFillHeart, AiOutlineMessage } from "react-icons/ai";
import "./Post.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import { likePost } from "../../api/postRrequest";
import { useDisclosure } from "@mantine/hooks";
import { Menu } from "@mantine/core";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getUserBasic } from "../../api/userRequest";
import { useEffect } from "react";

const Post = ({ data }) => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const navigate = useNavigate();
	const [liked, setLiked] = useState(data.likes.includes(user._id));
	const [likes, setLikes] = useState(data.likes.length);

	const [author, setAuthor] = useState("");
	const getAuthor = async () => {
		const author = await getUserBasic(data.userId);
		setAuthor(author.data);
	};
	useEffect(() => {
		getAuthor();
	}, []);

	const handleLike = () => {
		setLiked((prev) => !prev);
		likePost(data._id, user._id);
		liked ? setLikes((prev) => prev - 1) : setLikes((prev) => prev + 1);
	};
	const time = () => {
		const createdTime = new Date(data.createdAt);
		const currentTime = new Date();
		if (Math.round((currentTime - createdTime) / 1000) < 15) {
			return "Just Now";
		} else if (Math.round((currentTime - createdTime) / 1000) < 60) {
			return `${Math.round((currentTime - createdTime) / 1000)} Seconds Ago`;
		} else if (Math.round((currentTime - createdTime) / (1000 * 60)) < 60) {
			return Math.round((currentTime - createdTime) / (1000 * 60)) === 1
				? "1 MINUTE AGO"
				: Math.round(
						(currentTime - createdTime) / (1000 * 60) + " Minutes Ago"
				  );
		} else if (
			Math.round((currentTime - createdTime) / (1000 * 60 * 60)) === 1
		) {
			return "1 HOUR AGO";
		} else if (
			Math.round((currentTime - createdTime) / (1000 * 60 * 60)) < 24
		) {
			return `${Math.round(
				(currentTime - createdTime) / (1000 * 60 * 60)
			)} HOURS AGO`;
		} else if (
			Math.round((currentTime - createdTime) / (1000 * 60 * 60 * 24)) === 1
		) {
			return "Yesterday";
		} else if (
			Math.round((currentTime - createdTime) / (1000 * 60 * 60 * 24)) < 4
		) {
			return `${Math.round(
				(currentTime - createdTime) / (1000 * 60 * 60 * 24)
			)} DAYS AGO`;
		} else {
			const months = [
				"January",
				"Feburary",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			];
			const month = months[createdTime.getMonth() - 1];
			return `${createdTime.getDate()} ${month} ${createdTime.getFullYear()}`;
		}
	};
	return (
		<div className="Post">
			<div className="postHeader">
				<img
					src={author.profilePicture}
					alt=""
					onClick={() => {
						navigate(`../${author.username}`);
					}}
				/>
				<div className="postHeaderDetails">
					<span
						onClick={() => {
							navigate(`../${author.username}`);
						}}
					>
						{author.username}
					</span>
					<span>{data.location}</span>
				</div>
				<div>
					<ProfileMenu />
				</div>
			</div>
			<div className="postImage">
				<img src={data.image} alt="" />
			</div>
			<div className="postDetails">
				<div className="postReact">
					{liked ? (
						<AiFillHeart style={{ color: "red" }} onClick={handleLike} />
					) : (
						<AiOutlineHeart onClick={handleLike} />
					)}
					<AiOutlineMessage
						onClick={() => {
							navigate(`../post/${data._id}`);
						}}
					/>
					<RiSendPlaneLine />
				</div>
				<span>{likes} likes</span>

				<div className="postDesc">
					<span
						onClick={() => {
							navigate(`../${author.username}`);
						}}
					>
						{author.username}
					</span>
					<span> {data.desc}</span>
				</div>
				<div
					className="comment"
					onClick={() => {
						navigate(`../post/${data._id}`);
					}}
				>
					{data.comments.length > 1
						? `View ${data.comments.length} comments`
						: data.comments.length === 1
						? `View 1 comment`
						: ""}
				</div>
				<div className="postTime">{time()}</div>
			</div>
		</div>
	);
};

function ProfileMenu() {
	const [opened, handlers] = useDisclosure(false);
	return (
		<Menu
			opened={opened}
			onOpen={handlers.open}
			onClose={handlers.close}
			control={
				<button>
					<BiDotsHorizontalRounded />
				</button>
			}
			position="bottom"
			placement="end"
			size="sm"
			gutter={-7}
			shadow="lg"
		>
			<Menu.Label>General</Menu.Label>
			<Menu.Item icon={<MdOutlineModeEdit />}>Edit</Menu.Item>
			<Menu.Item icon={<TiDeleteOutline />}>Delete Post</Menu.Item>
		</Menu>
	);
}

export default Post;
