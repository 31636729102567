import { useEffect } from "react";
import { useState } from "react";
import { getUserBasic } from "../../api/userRequest";

const Comment = (props) => {
	const [user, setUser] = useState("");
	const getUser = async () => {
		const user = await getUserBasic(props.comment.userId);
		setUser(user.data);
	};
	useEffect(() => {
		getUser();
	}, []);

	return (
		<div>
			<img src={user.profilePicture} alt="" />
			<div>
				<span>{user.username}</span>
				<span>{props.comment.data}</span>
			</div>
		</div>
	);
};

export default Comment;
