import React from "react";
import { useSelector } from "react-redux";
import "./ProfileCard.css";
import { useNavigate } from "react-router-dom";

const ProfileCard = () => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const navigate = useNavigate();
	const name = `${user.firstname} ${user.lastname}`;

	return (
		<div className="ProfileCard">
			<div className="ProfileName">
				<div className={user.story ? "haveStory" : ""}>
					<img
						src={user.profilePicture}
						alt=""
						className="profileCardImage"
						onClick={() => {
							navigate(`../${user.username}`);
						}}
					/>
				</div>

				<div
					className="profileNames"
					onClick={() => {
						navigate(`../${user.username}`);
					}}
				>
					<span>{name.length > 25 ? name.slice(0, 21) + "..." : name}</span>
					<span>
						@{" "}
						{user.username.length > 27
							? user.username.slice(0, 24) + "..."
							: user.username}
					</span>
				</div>
			</div>

			<div className="followStatus">
				<hr />
				<div>
					<div className="follow">
						<span>{user.followers.length}</span>
						<span>Followers</span>
					</div>
					<div className="vl"></div>
					<div className="follow">
						<span>{user.following.length}</span>
						<span>Followings</span>
					</div>
				</div>
				<hr />
			</div>
		</div>
	);
};

export default ProfileCard;
