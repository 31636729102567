import React from "react";
import "./Story.css";

const Story = ({ person }) => {
	if (!person.story) {
		return "";
	}
	return (
		<div className="Story">
			<div className="haveStory">
				<img src={person.profilePicture} alt="" className="StoryImg" />
			</div>
			<span>
				{person.username.length > 11
					? person.username.slice(0, 8) + "..."
					: person.username}
			</span>
		</div>
	);
};

export default Story;
