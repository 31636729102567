import { combineReducers } from "redux";

import authReducer from "./AuthReducer";
import postReducer from "./PostReducer";
import generalReducer from "./GeneralReducer";

export const reducers = combineReducers({
	authReducer,
	generalReducer,
	postReducer,
});
