import React, { useEffect, useState } from "react";
import "./Auth.css";
import Logo from "../../img/onlyNameLogo.png";
import { logIn, signUp } from "../../actions/authActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BiLockOpen, BiLockOpenAlt, BiUser } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import {
	MdDriveFileRenameOutline,
	MdOutlineDriveFileRenameOutline,
} from "react-icons/md";
import toast from "react-hot-toast";

const Auth = () => {
	const initialState = {
		firstname: "",
		lastname: "",
		username: "",
		password: "",
		confirmpass: "",
		email: "",
	};
	const loading = useSelector((state) => state.authReducer.loading);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: "auth" });
	}, []);

	const [isSignUp, setIsSignUp] = useState(false);

	const [data, setData] = useState(initialState);

	const [confirmPass, setConfirmPass] = useState(true);

	// Reset Form
	const resetForm = () => {
		setData(initialState);
		setConfirmPass(confirmPass);
	};

	// handle Change in input
	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	// Form Submission
	const handleSubmit = (e) => {
		setConfirmPass(true);
		if (isSignUp) {
			if (!data.firstname) toast.error("Please enter Firstname");
			else if (!data.lastname) toast.error("Please enter Lastname");
			else if (!data.username) toast.error("Please enter Username!");
			else if (!data.email) toast.error("Please enter Email");
			else if (!data.password) toast.error("Please enter Password!");
			else if (!data.confirmpass) toast.error("Please enter confirm password");
			else {
				if (data.password === data.confirmpass)
					dispatch(signUp(data, navigate));
				else {
					setConfirmPass(false);
					toast.error("Confirm password does not match");
				}
			}
		} else {
			if (!data.username) toast.error("Please enter username!");
			if (!data.password) toast.error("Please enter Password!");
			if (data.username && data.password) dispatch(logIn(data, navigate));
		}
	};
	return (
		<div className="Auth">
			<div className="authForm">
				<img className="authLogo" src={Logo} alt="" />
				<span className="authTitle">{isSignUp ? "Register" : "Login"}:</span>
				<div className="authInputs">
					{isSignUp && (
						<div className="inputField">
							<MdDriveFileRenameOutline />
							<input
								required
								type="text"
								placeholder="First Name"
								name="firstname"
								value={data.firstname}
								onChange={handleChange}
							/>
						</div>
					)}

					{isSignUp && (
						<div className="inputField">
							<MdOutlineDriveFileRenameOutline />
							<input
								required
								type="text"
								placeholder="Last Name"
								name="lastname"
								value={data.lastname}
								onChange={handleChange}
							/>
						</div>
					)}

					<div className="inputField">
						<BiUser />
						<input
							required
							type="text"
							placeholder="Username"
							name="username"
							value={data.username}
							onChange={handleChange}
						/>
					</div>
					{isSignUp && (
						<div className="inputField">
							<HiOutlineMail />
							<input
								required
								type="text"
								name="email"
								placeholder="email"
								onChange={handleChange}
							/>
						</div>
					)}

					<div className="inputField">
						<BiLockOpenAlt />
						<input
							required
							type="password"
							placeholder="Password"
							name="password"
							value={data.password}
							onChange={handleChange}
						/>
					</div>
					{isSignUp && (
						<div className="inputField">
							<BiLockOpen />
							<input
								required
								type="password"
								name="confirmpass"
								placeholder="Confirm Password"
								onChange={handleChange}
							/>
						</div>
					)}
				</div>
				{!confirmPass && (
					<span className="authWarning">*Confirm password is not same</span>
				)}
				<span
					className="authLink"
					onClick={() => {
						resetForm();
						setIsSignUp((prev) => !prev);
					}}
				>
					{isSignUp ? "Already have an account? " : "Don't have an account? "}
					<span>{isSignUp ? "LogIn" : "SignUp"}</span>
				</span>
				<button
					onClick={() => handleSubmit()}
					className="button authButton"
					type="Submit"
					disabled={loading}
				>
					{loading ? "Loading..." : isSignUp ? "SignUp" : "Login"}
				</button>
				<p>&#169;TollyGram all rights reserved</p>
			</div>
			<div className="authFooter">
				<span>English (UK)</span>
				<span>© 2022 Tollygram from Vaibhav</span>
			</div>
		</div>
	);
};

export default Auth;
