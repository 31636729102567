import {
	BiHomeAlt,
	BiHeart,
	BiMessageSquareDots,
	BiUser,
} from "react-icons/bi";
import { RiAddCircleLine } from "react-icons/ri";
import "./NavMenu.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Navmenu() {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.authReducer.authData);

	return (
		<div className="nav__menu">
			<ul className="nav__list">
				<li className="nav__item">
					<button className="nav__link active__link">
						<BiHomeAlt
							onClick={() => {
								navigate("../Home");
							}}
						/>
					</button>
				</li>

				<li className="nav__item">
					<button className="nav__link">
						<BiHeart />
					</button>
				</li>

				<li className="nav__item">
					<button className="nav__link">
						<RiAddCircleLine />
					</button>
				</li>

				<li className="nav__item">
					<button className="nav__link">
						<BiMessageSquareDots />
					</button>
				</li>

				<li className="nav__item">
					<button className="nav__link">
						<BiUser
							onClick={() => {
								navigate(`../${user.username}`);
							}}
						/>
					</button>
				</li>
			</ul>
		</div>
	);
}
