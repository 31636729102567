import toast from "react-hot-toast";
import * as AuthApi from "../api/authRequests";
export const logIn = (formData, navigate) => async (dispatch) => {
	dispatch({ type: "AUTH_START" });
	try {
		const { data } = await AuthApi.logIn(formData);
		dispatch({ type: "AUTH_SUCCESS", data: data });
		navigate("../home", { replace: true });
	} catch (error) {
		toast.error(error.response.data?error.response.data:"Some Error Occured");
		dispatch({ type: "AUTH_FAIL" });
	}
};

export const signUp = (formData, navigate) => async (dispatch) => {
	dispatch({ type: "AUTH_START" });
	try {
		const { data } = await AuthApi.signUp(formData);
		dispatch({ type: "AUTH_SUCCESS", data: data });
		navigate("../home", { replace: true });
	} catch (error) {
		toast.error(error.response.data?error.response.data:"Some Error Occured");
		dispatch({ type: "AUTH_FAIL" });
	}
};

export const logOut = () => async (dispatch) => {
	dispatch({ type: "LOG_OUT" });
};
