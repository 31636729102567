import "./App.css";
import Auth from "./pages/auth/Auth";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";

import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import PostPage from "./pages/postPage/PostPage";
import { useEffect } from "react";
import StatusBar from "./components/statusBar/StatusBar";
import Navmenu from "./components/navMenu/NavMenu";

function App() {
	window.addEventListener("load", function () {
		let vh = window.innerHeight;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	});
	const user = useSelector((state) => state.authReducer.authData);
	return (
		<div className="App">
			<Toaster />
			{user && (
				<>
					<StatusBar />
					<Navmenu />
				</>
			)}
			<Routes>
				<Route
					path="/"
					element={user ? <Navigate to="home" /> : <Navigate to="auth" />}
				/>
				<Route
					path="/home"
					element={user ? <Home /> : <Navigate to="../auth" />}
				/>
				<Route
					path="/auth"
					element={user ? <Navigate to="../home" /> : <Auth />}
				/>
				<Route path="/:userId" element={<Profile />} />
				<Route path="/post/:postId" element={<PostPage />} />
			</Routes>
		</div>
	);
}

export default App;
