import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getFollowing } from "../../api/userRequest";
import { FaPlus } from "react-icons/fa";
import Story from "../story/Story";
import StoryModal from "../storyModal/StoryModal";
import "./Stories.css";

const Stories = (props) => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const [storyModelOpened, setStoryModelOpened] = useState(false);
	const [persons, setPersons] = useState([]);
	useEffect(() => {
		const fetchPersons = async () => {
			const { data } = await getFollowing(props.id);
			setPersons(data);
		};
		fetchPersons();
	}, []);
	const handleAdd = () => {
		if (user.story) return;
		setStoryModelOpened(true);
	};
	return (
		<>
			<StoryModal
				storyModelOpened={storyModelOpened}
				setStoryModelOpened={setStoryModelOpened}
			/>
			<div className="Stories">
				<div>
					<div className="Story" onClick={handleAdd}>
						<div className={user.story ? "haveStory" : "noStoryUser"}>
							<img src={user.profilePicture} alt="" className="StoryImg" />
							<div className="addStorySignUser">
								<FaPlus />
							</div>
						</div>
						<span>
							{user.username.length > 10
								? user.username.slice(0, 9) + "..."
								: user.username}
						</span>
					</div>
					{persons.map((person, id) => {
						return (
							<>
								<Story person={person} key={id} />
							</>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default Stories;
