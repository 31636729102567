import React, { useEffect } from "react";
import PostSide from "../../components/postSide/PostSide";
import ProfileSide from "../../components/profileSide/ProfileSide";
// import RightSide from "../../components/rightSide/RightSide";
import "./Home.css";
import { useDispatch } from "react-redux";

const Home = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: "home" });
	}, []);

	return (
		<div className="Home">
			<ProfileSide />
			<PostSide />
			{/* <RightSide /> */}
		</div>
	);
};

export default Home;
