import React from "react";
import "./ExploreCard.css";
import User from "../user/User";
import { useEffect } from "react";
import { useState } from "react";
import { getAllUser } from "../../api/userRequest";

const ExploreCard = () => {
	const [persons, setPersons] = useState([]);
	useEffect(() => {
		const fetchPersons = async () => {
			const { data } = await getAllUser();
			setPersons(data);
		};
		fetchPersons();
	}, []);

	return (
		<div className="FollowersCard">
			<span>Suggestions For You</span>

			{persons.map((person, id) => {
				return <User person={person} key={id} />;
			})}
		</div>
	);
};

export default ExploreCard;
