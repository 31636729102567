import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { followUser, unfollowUser } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";

const User = ({ person }) => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [following, setFollowing] = useState(
		user.following.includes(person._id)
	);

	useEffect(() => {
		if (user.following.includes(person._id)) {
			setFollowing(true);
			setLoading(false);
		} else {
			setFollowing(false);
			setLoading(false);
		}
	}, [user.following.includes(person._id)]);

	const handleFollow = async () => {
		setLoading(true);
		if (following) {
			dispatch(unfollowUser(person._id, user));
		} else {
			dispatch(followUser(person._id, user));
		}
	};
	if (person._id === user._id) return "";
	const name = `${person.firstname} ${person.lastname}`;
	return (
		<div className="follower">
			<div>
				<div className={person.story ? "haveStory" : ""}>
					<img
						src={person.profilePicture}
						alt=""
						className="followerImage"
						onClick={() => {
							navigate(`../${person.username}`);
						}}
					/>
				</div>
				<div
					className="name"
					onClick={() => {
						navigate(`../${person.username}`);
					}}
				>
					<span>{name.length > 18 ? name.slice(0, 15) + "..." : name}</span>
					<span>
						@{" "}
						{person.username.length > 15
							? person.username.slice(0, 12) + "..."
							: person.username}
					</span>
				</div>
			</div>
			<button className="button fc-button" onClick={handleFollow}>
				{loading ? "loading" : following ? "Unfollow" : "Follow"}
			</button>
		</div>
	);
};

export default User;
