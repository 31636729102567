import React from "react";
import "./Posts.css";
import Post from "../post/Post";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTimelinePosts } from "../../actions/postAction";
import ExploreSlide from "../exploreSlide/ExploreSlide";
import Loader from "../loader/Loader";

const Posts = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.authReducer.authData);
	const { posts, loading } = useSelector((state) => state.postReducer);

	useEffect(() => {
		dispatch(getTimelinePosts(user._id));
	}, [user]);
	return (
		<div className="Posts">
			{loading ? (
				<div className="postLoader">
					<Loader />
				</div>
			) : (
				posts.map((post, id) => {
					return (
						<>
							<Post data={post} key={id} />
							{id === 0 && <ExploreSlide key={`exploreSlide${id}`} />}
						</>
					);
				})
			)}
			{!loading && posts.length === 0 && (
				<div className="followMessage">
					<span>Seems like their's nothing to show in your feed...</span>
					<span>Follow people to see their posts</span>
					<ExploreSlide key="exploreSlide" />
				</div>
			)}
		</div>
	);
};

export default Posts;
