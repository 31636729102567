const generalReducer = (state = { currentPage: "auth" }, action) => {
	switch (action.type) {
		case "home":
			return { ...state, currentPage: "home" };
		case "auth":
			return { ...state, currentPage: "auth" };
		case "comments":
			return { ...state, currentPage: "comments" };
		case "profile":
			return { ...state, currentPage: "profile" };
		default:
			return state;
	}
};

export default generalReducer;
