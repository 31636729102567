import { useRef } from "react";
import Logo from "../../img/logo.png";
import { GrAdd } from "react-icons/gr";
import { BiHomeAlt, BiHeart, BiMessageSquareDots } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import "./StatusBar.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StatusBar = () => {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.authReducer.authData);
	const { currentPage } = useSelector((state) => state.generalReducer);
	//useref for focusing of search when clicking svg of outer-box
	const search = useRef();
	return (
		//statusbar
		<div className="StatusBar">
			{currentPage === "home" && (
				<>
					<div className="statusBarLogo">
						<img src={Logo} alt="" />
					</div>

					<div
						className="statusBarSearch"
						onClick={() => search.current.focus()}
					>
						<input ref={search} type="text" placeholder="#explore" />
						<HiOutlineSearch />
					</div>
				</>
			)}

			<div className="menu">
				<div>
					<GrAdd />
				</div>
				<div>
					<BiHomeAlt
						onClick={() => {
							navigate("../Home");
						}}
					/>
				</div>
				<div>
					<BiHeart />
				</div>
				<div>
					<BiMessageSquareDots />
				</div>
				<div>
					<img
						src={user.profilePicture}
						alt=""
						className="statusBarProfilePicture"
						onClick={() => {
							navigate(`../${user.username}`);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default StatusBar;
