import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { followUser, unfollowUser } from "../../actions/userAction";

const ExploreCardSlideUser = ({ person }) => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [following, setFollowing] = useState(
		user.following.includes(person._id)
	);

	useEffect(() => {
		if (user.following.includes(person._id)) {
			setFollowing(true);
			setLoading(false);
		} else {
			setFollowing(false);
			setLoading(false);
		}
	}, [user.following.includes(person._id)]);

	const handleFollow = async () => {
		setLoading(true);
		if (following) {
			dispatch(unfollowUser(person._id, user));
		} else {
			dispatch(followUser(person._id, user));
		}
	};
	const name = `${person.firstname} ${person.lastname}`;
	if (person._id === user._id) return "";

	return (
		<div className="exploreSlideUser">
			<div className="exploreSlideUserUpper">
				<img
					src={person.profilePicture}
					alt=""
					className="exploreSlideImage"
					onClick={() => {
						navigate(`../${person.username}`);
					}}
				/>
			</div>
			<div className="exploreSlideUserLower">
				<span
					onClick={() => {
						navigate(`../${person.username}`);
					}}
				>
					{name.length > 18 ? name.slice(0, 15) + "..." : name}
				</span>
				<span
					onClick={() => {
						navigate(`../${person.username}`);
					}}
				>
					@
					{person.username.length > 17
						? person.username.slice(0, 14) + "..."
						: person.username}
				</span>
				<button className="button fc-button" onClick={handleFollow}>
					{loading ? "loading" : following ? "Unfollow" : "Follow"}
				</button>
			</div>
		</div>
	);
};
export default ExploreCardSlideUser;
