import { Modal } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./StoryModal.css";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import ImageCropper from "../imageCropper/ImageCropper";
import toast from "react-hot-toast";
import getCroppedImg from "../imageCropper/cropImageFunctions";
import { updateUser } from "../../actions/userAction";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../firebase/Firebase";

const StoryModal = (props) => {
	const { user } = useSelector((state) => state.authReducer.authData);
	const dispatch = useDispatch();
	const storyImageRef = useRef();
	const [storyImage, setStoryImage] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [loading, setLoading] = useState(false);
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setStoryImage(img);
		}
	};
	const handleSubmit = async () => {
		setLoading(true);
		if (!storyImage) {
			toast.error("Please select a media for story");
			setTimeout(() => {
				setLoading(false);
			}, 750);
			return;
		}
		await cropImage();
	};
	const cropImage = useCallback(async () => {
		try {
			const dataurl = await getCroppedImg(
				URL.createObjectURL(storyImage),
				croppedAreaPixels
			);
			const dataURLtoFile = (dataurl, filename) => {
				const arr = dataurl.split(",");
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) u8arr[n] = bstr.charCodeAt(n);

				return new File([u8arr], filename, { type: mime });
			};
			const temp = dataURLtoFile(dataurl, storyImage.name);
			setCroppedImage(temp);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels]);
	useEffect(() => {
		upload();
	}, [croppedImage]);
	const upload = () => {
		if (!croppedImage) {
			return;
		}
		let userData = user;
		const filename = Date.now() + storyImage.name;
		const storageRef = ref(storage, `${user._id}/story/${filename}`);
		const uploadTask = uploadBytesResumable(storageRef, croppedImage);
		const promise = new Promise((resolve, reject) => {
			uploadTask.on(
				"state_changed",
				(snapshot) => {},
				(error) => {
					console.log(error);
					setLoading(false);
					reject(error);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						userData.story = url;
						dispatch(updateUser(user._id, userData));
						setLoading(false);
						setStoryImage(null);
						props.setStoryModelOpened(false);
						resolve();
					});
				}
			);
		});
		toast.promise(promise, {
			loading: "Uploading...",
			success: <b>Uploaded</b>,
			error: <b>Upload Failed!</b>,
		});
	};

	return (
		<Modal
			centered
			opened={props.storyModelOpened}
			onClose={() => props.setStoryModelOpened(false)}
			withCloseButton={false}
			size={380}
			padding={0}
			transition="fade"
			transitionDuration={300}
		>
			<div className="storyModal">
				<span>Create Story</span>
				<hr />
				<div className="storyModalContent">
					<div
						className={`storyModalImageInput ${
							!storyImage && "storyModalImageInputNoPhoto"
						}`}
					>
						{storyImage && (
							<ImageCropper
								image={URL.createObjectURL(storyImage)}
								aspectRatio={9 / 16}
								setCroppedImage={setCroppedImage}
								setCroppedAreaPixels={setCroppedAreaPixels}
							/>
						)}
						{!storyImage && (
							<div
								className="storyModalImageInputSelectPhoto"
								onClick={() => storyImageRef.current.click()}
							>
								<MdOutlineAddPhotoAlternate />
								<span>select photo</span>
							</div>
						)}
					</div>
					{storyImage && (
						<div
							className="storyDeleteOption"
							onClick={() => setStoryImage(null)}
						>
							<RiDeleteBinLine />
							Delete
						</div>
					)}
				</div>
				<div className="storyModalButtonWrapper">
					<button
						className={`storyModalButton ${
							loading && "storyModalButtonActive"
						}`}
						onClick={handleSubmit}
					>
						<div className="svg-wrapper-1">
							<div className="svg-wrapper">
								<svg
									stroke="currentColor"
									fill="currentColor"
									strokeWidth="0"
									viewBox="0 0 24 24"
									height="1.8em"
									width="1.8em"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"></path>
								</svg>
							</div>
						</div>
						<span className="storyModalButtonText">Post</span>
						<span className="storyModalButtonTextLoading">Uploading...</span>
					</button>
				</div>
			</div>
			<div style={{ display: "none" }}>
				<input
					type="file"
					name="myImage"
					ref={storyImageRef}
					onChange={onImageChange}
				/>
			</div>
		</Modal>
	);
};

export default StoryModal;
