import React from "react";
import ProfileCard from "../profileCard/ProfileCard";
import ExploreCard from "../exploreCard/ExploreCard";
import "./ProfileSide.css";

function ProfileSide() {
	return (
		<div className="ProfileSide">
			<ProfileCard />
			<ExploreCard />
			<hr />
			<div className="profileSideFooter">
				<span>&#169; 2022 TOLLYGRAM BY VAIBHAV</span>
				<span>All rights reserved</span>
			</div>
		</div>
	);
}

export default ProfileSide;
