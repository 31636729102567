import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";

const ImageCropper = (props) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		props.setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	return (
		<Cropper
			image={props.image}
			crop={crop}
			zoom={zoom}
			aspect={props.aspectRatio}
			onCropChange={setCrop}
			onCropComplete={onCropComplete}
			onZoomChange={setZoom}
		/>
	);
};

export default ImageCropper;
