import axios from "axios";

const API = axios.create({
  baseURL: "https://tollygram.azurewebsites.net/",
  // baseURL: "http://localhost:5000",
});

export const getAllUser = () => API.get("/user");
export const getFollowing = (id) => API.get(`/user/${id}/following`);
export const getUser = (user) => API.get(`/user/${user}`);
export const updateUser = (id, formData) => API.put(`/user/${id}`, formData);
export const followUser = (id, data) => API.put(`/user/${id}/follow`, data);
export const unfollowUser = (id, data) => API.put(`/user/${id}/unfollow`, data);
export const getUserBasic = (user) => API.get(`/user/${user}/getUserBasic`);
